
div.questionHeader{
    display: flex;
    justify-content : space-between;
}

  /* Style for the heading */
  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  /* Style for the "Ask Question" button */
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Style for the question form */
  textarea {
    width: 100%;
    height: 80px;
    margin: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .qnaBox li{
    padding : 10px;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    margin: 16px 0px;
    text-decoration: none;
  }
  
  .qnaBox ul{
    list-style-type: none;
  }
  

  