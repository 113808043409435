body {
    background-color: #f1f3f6;
}

p#slider-text {
    font-size: 1.7rem;
    text-align: center;
    font-weight: 500;
    width: inherit;
    color: white;
}

.banner-slider-1 {
    position: relative;
    top: 10vh;
    width: 98vw;
    height: 40vh;
    margin: auto;
    background-color: black;
    display: flex;
}

.productByRates {
    display: flex;
    position: relative;
    top: 15vh;
    width: 95vw;
    height: 34vh;
    margin-left: 3vw;
    margin-top: 6vh;
}


.product-slider-1 {
    display: flex;
    position: relative;
    top: 15vh;
    width: 100vw;
    height: 35vh;
    overflow: hidden;
    align-items: center;
    margin-top: 8vh;
}

.product-slider-2 {
    display: flex;
    position: relative;
    top: 20vh;
    height: 35vh;
    overflow: hidden;
    align-items: center;
    margin-top: 8vh;
}

.homepage-slider-2 {
    width: 83vw;
    height: 25vh;
    background-color: cadetblue;
}

.newArrival {
    background-color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1);
    width: 170px;
    height: 250px;
    position: relative;
    margin-left: 3vw;
    display: flex;
    align-items: center;
}

.topProducts {
    background-color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1);
    width: 170px;
    height: 250px;
    position: relative;
    display: flex;
    align-items: center;
}

.bestProduct {
    position: absolute;
    top: 45vh;
    width: 83vw;
    height: 25vh;
    margin-left: 3vw;
    margin-top: 3vh;

}


.topDeals {
    background-color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1);
    width: 200px;
    height: 250px;
    position: relative;
    display: flex;
    align-items: center;
}

.circularCard {
    width: 200px;
    height: 200px;
    position: relative;
    border-radius: 50%;
    background-color: #c4c4c4;
    margin-left: 20px;
    box-shadow: rgb(6 24 44 / 40%) 0px 0px 0px 2px, rgb(6 24 44 / 65%) 0px 4px 6px -1px, rgb(255 255 255 / 8%) 0px 1px 0px inset;
    cursor: pointer;
}

p.rate {
    font-size: 5.5rem;
    display: flex;
    justify-content: center;
    color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1);
}

p.under {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    position: relative;
    top: 6vh;
    font-weight: bold;
}

.zzabApp {
    background-image: linear-gradient(0deg, rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1) 0%, rgba(40.37499949336052, 40.37499949336052, 40.37499949336052, 1) 100%);
    width: 100vw;
    height: 35vh;
    position: absolute;
    top: 205vh;
    color: white;
}

.zzabApp span.text1 {
    display: block;
    font-size: 40px;
    position: relative;
    left: 8vw;
    top: 6vh;
}

.zzabApp span.text2 {
    display: block;
    font-size: 20px;
    width: 29vw;
    position: relative;
    left: 8vw;
    top: 8vh;
}

.googlePlayBtn {
    background-image: url(../../assets/images/google_play.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 24vh;
    height: 8vh;
    position: relative;
    left: 78vw;
}

.product-slider-1 .slick-slider.slick-initialized {
    width: 76vw;
    margin-left: 3%;
}

.product-slider-1 .slick-prev {
    left: -37px;
    z-index: 1;
}

.product-slider-1 .slick-next {
    right: -34px;
    z-index: 1;
}

.product-slider-2 .slick-slider.slick-initialized {
    width: 76vw;
    margin: 0% 3%;
}

.product-slider-2 .slick-prev {
    left: -37px;
    z-index: 1;
}

.product-slider-2 .slick-next {
    right: -35px;
    z-index: 1;
}

.homepage-footer {
    position: relative;
    top: 100vh;
}