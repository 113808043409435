.userProfileContainer {
    position: relative;
    top: 10vh;
    min-height: 80vh;
    width: 90%;
    margin: auto;
    display: flex;
    gap: 8%;
}

.profileRightContainer {
    width: 85%;
    height: max-content;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-color: white;
}

.profileLeftContainer {
    width: 20%;
}

.profileProducts{
    padding: 3%;
}

p.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    padding: 2rem;
    text-transform: uppercase;
    background-color: #ad657e;
    color: white;
}

.profileFooter {
    position: relative;
    top: 13vh;
}

a.productLink {
text-decoration: none;
color : black
}

a.productLink:hover
{
color : #982E53
}