.categoryContainer {
    position: relative;
    height: max-content;
    width: 90%;
    display: flex;
    gap: 6%;
    padding: 38px;
    justify-content: center;
    align-items: center;
}

.topCategory {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 2%;
}

.categoryBox {
    display: flex;
    height: 7vh;
    border: solid;
    justify-content: space-between;
    cursor: pointer;
    background-color: #982E53;
    color: white;
    align-items: center;
    border-radius: 15px;
}

.categoryBox:hover {
    background-color: white;
    color: #982E53;
    border: solid 1px #982E53;
}

.subCategory1 {
    display: flex;
    flex-direction: column;
    gap: 2%;
    width: 25%
}

.subCategory2 {
    width: 25%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2%;
}

button.btn-addCategory {
    padding: 0px 3%;
    border-radius: 25px;
    height: 33px;
    background-color: #982E53;
    color: white;
    border: solid 1px
}

button.btn-addCategory:hover {
    background-color: white;
    color: #982E53;
    border-color: #982E53;
}