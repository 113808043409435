.filterBar {
    width: 20%;
    padding: 1.5rem;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%);
    background-color: white;
    display: flex;
    flex-flow: column;
    gap: 2vh;
    overflow-y: auto;
}

.filterBar::-webkit-scrollbar { width: 0 !important }
.filterBar { overflow: -moz-scrollbars-none; }
.filterBar { -ms-overflow-style: none; }


.priceFilter {
    border-top: solid 1px;
    padding: 8% 0;
    display: flex;
    gap: 8%;
}

#priceRangeBtn {
  background-color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1);
    color: white;
    border: none;
}

#priceRangeBtn:hover{
    color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1);
    background-color: transparent;
    border: solid 1px;
    border-color : rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1);
}

.filter {
    border-top: solid 1px;
    padding: 8% 0;
    display: flex;
    flex-direction: column;
}

input.priceRangeInput {
    width: 5rem;
    text-align: center;
}


