.checkoutPageContainer {
    min-height: 100vh;
    width: 98%;
    margin: auto;
    position: relative;
    top: 9vh;
    display: flex;
    align-items: normal;
    padding: 25px;
    gap:3%;
}

.checkoutLeftContainer {
    display: flex;
    width: 120%;
    flex-direction: column;
}

.checkoutRightContainer {
    width: 60%;
}

.checkoutHeading{
    color : #982E53;
    font-size: 18px;
    font-weight: 600;
}

.selectAddress {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 5px 10px;
    margin-top: 30px;
}