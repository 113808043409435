.cart {
    display: flex;
    position: relative;
    top: 10vh;
    gap: 1%
}

.cartHead {
    display: flex;
    align-items: center;
    gap: 66%;
}

span#cartItemPrice {
    font-weight: 500;
}

.cartList {
    width: 60%;
    position: relative;
    margin-left: 5%;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 2%;
    border-radius: 4px;
}

#cartHeading {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.priceContainer {
    width: 25%;
    text-align: center;
}

.cartFooter {
    position: relative;
    top: 22vh;
}