.header {
    height: 10vh;
}

.flexContainer {
    display: flex;
    border-bottom: solid 1px grey;
    align-items: center;
    gap: 40%;
}

.badge {
    background-color: green;
    padding: 12px;
    color: white
}

.addProduct {
    width: 90%;
    margin: auto;
    min-height: 60vh;
}

.addCategoryContainer {
    background-color: white;
}

.addProductForm {
    margin-top: 3vh;
    transition: 550ms;
    background-color: white;
}

.addProductImages {
    margin-top: 3vh;
    background-color: white;
}

.addProductForm p:hover,
.addCategoryContainer p:hover,
.addProductImages p:hover,
.flexContainer:hover {
    background-color: #ad657e;
    color: white;
}

button.submitBtn {
    padding: 0.5rem 4rem;
    border: solid 1px;
    border-radius: 11px;
    background-color: #982E53;
    color: white;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 7%);
    margin-top: 5vh;
}

button.submitBtn:hover {
    background-color: white;
    color: #982E53;
    border: solid 1px;
    border-color: #982E53;
}

.submitBtnBox {
    display: flex;
    justify-content: end;
}

.addProductFooter {
    margin-top: 3vh;
}

.hide {
    display: none;
    transition: all 1ms;
}

.show {
    display: block;
    transition: 500ms ease-in-out;
}

.show.categoryHeading {
    background-color: #ad657e;
}