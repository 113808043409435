.pageContainer {
    position: relative;
    top: 10vh;
    display: flex;
    width: 98%;
    margin: auto;
    gap: 1rem;
}

.productsContainer {
    width: 80%;
    background-color: white;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%);
    min-height: 83vh;
    margin: auto;

}

.products {
    display: flex;
    background-color: white;
    overflow-y: auto;
    gap: 25px;
    flex-wrap: wrap;
    padding: 20px;
}

.products::-webkit-scrollbar {
    width: 0 !important
}

.products {
    overflow: -moz-scrollbars-none;
}

.products {
    -ms-overflow-style: none;
}


.text {
    font-size: 1.3rem;
    font-weight: 500;
}

.pageFooter {
    position: relative;
    top: 41vh;
}

.result {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    justify-content: space-between;
}

#sortOptions {
    padding: 5px;
    border-radius: 5px;
}