.uploadImages {
    width: 96%;
    margin: auto;
    display: flex;
    height: max-content;
    justify-content: space-between;
    align-items: center;
}

.preview-box {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    gap : 10px;
}

i.uploadIcon.uil.uil-image-plus {
    font-size: 6rem;
}