.basicDetailsContainer {
    padding: 0px 7%;
    display: flex;
    justify-content: space-between;
}

.nameInput {
    display: flex;
    gap: 3%;
}

label.details-label {
    font-size: 1rem;
    display: block;
    margin: 2px;
    margin-top: 9px;
    font-weight: 500;
}

input.details-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    
}

button.detailsEditBtn {
    margin: 4vh;
    padding: 1% 6%;
    border-radius: 25px;
    float: right;
    border: solid 1px;
    background: #982E53;
    color: white;
}