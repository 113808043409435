.slick-slider {
    width: inherit;
}

.slick-prev::before {
    content: '<<';
}
.slick-next::before {
    content: '>>';
}

.scroll-btn{
    background-color: red;
}