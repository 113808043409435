Link {
    text-decoration: none;
}

.product-card {
    margin-left: 10px;
    background-color: white;
    border-radius: 3px;
    color: black;
    width: 250px;
    transition: opacity 0.3s ease-in-out;
    margin: 20px 0;
    border:solid 1px;
    border-color: #982E53;
}

.product-card:hover {
    color: #982E53;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border : dotted 2px;
}

img.product-card-img {
    object-fit: fill;
    min-width: 100%;
    max-width: 100%;
    height: 280px;
    transition: all 0.5s ease-in-out;
}

span#ListPrice {
    font-size: 1.3rem;
    font-weight: 500;
}

span#MRP {
    text-decoration: line-through;
    color: grey;
    font-size: .9rem;
}

span#pName {
    font-size: 1.1rem;
    font-weight:400;

}

.productNameContainer {
    display: block;
    max-height: 30px;
    min-height: 30px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    width: 220px;
   
}
