div.productDetails {
    display: flex;
    justify-content: space-between;
    padding: 2%;
}

.addImage {
    display: flex;
    align-items: center;
}

.col1,
.col2 {
    width: 45%;
}

input.productForm-input {
    display: block;
    border: none;
    width: 100%;
    background: #EAEAEA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    margin-bottom: 1.5vh;
    padding: 8px;
}

textarea.productForm-input {
    display: block;
    border: none;
    width: 100%;
    background: #EAEAEA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 4px;
    height: 27vh;
}


label.productForm-label {
    font-weight: 400;
    font-size: 1.3rem;
    color: #982E53 ;
}

form.addProductForm {
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    background-color: white;
    margin: auto;
    padding: 1%;
    min-height: 60vh;
    margin-top: 2vh;
}







