li{
    cursor: pointer;
}

a {
    text-decoration: none;
    color: white
}

.navbar {
    position: relative;
    background-color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1);
    width: 100vw;
    height: 8vh;
    position: fixed;
    display: flex;
    z-index: 11;
}

.header-logo {
    width: 45px;
    height: 42px;
    position: relative;
    left: 21px;
    background-image: url(../../assets/images/logo.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.Sell-btn {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669);
    width: 87px;
    height: 37px;
    position: absolute;
    left: 76vw;
    border-radius: 18.5px;
    border: none;
    top: 13px;
}

.nav {
    margin: auto;
    flex-direction: row;
    position: absolute;
    left: 85vw;
    color: white;
}

input.search {
    width: 22vw;
    height: 35px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding-left: 5px;
}

form.searchBar {
    position: absolute;
    left: 13vw;
    height: 35px;
    border-radius: 10px;
    display: flex;
}

i.uil.uil-search {
    display: inline-table;
    color: #982e53;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 25px;
    font-size: 20px;
}

i.uil.uil-align-justify {
    color: white;
}

button.menuBtn {
    position: absolute;
    left: 10vw;
    border: none;
    background-color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1);
}

.menuBox {
    background-color: white;
    height: fit-content;
    max-height: 50vh;
    min-height: 40vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* mozilla */
    background: rgb(255, 255, 255);
    width: 80vw;
    margin-left: 10vw;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.menuBox::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome browsers */
}

.mainCategory {
    display: inline-block;
    color: #982e53;
    opacity: 1;
    width: 18vw;
    padding: 6px;
    height: 37px;
    text-align: center;
    background-color: white;
    box-shadow: none;
    border-color: #982e53;
    border-width: 2px;
    margin: 2rem;
}

button.mainCategory:hover {
    background-color: #982e53;
    color: white;
}

button.mainCategory.active {
    background-color: #982e53;
    color: white;
}

.menu {
    position: sticky;
    top: 0;
    background-color: white;
}

.category {
    margin-left: 3vw;
    display: flex;
    gap: 5%;
    margin-bottom : 1rem
}

span.subMenuHeading {
    font-size: 1rem;
    font-weight: 500;
}

li.sub-menu {
    margin-top: 0.5rem;
    margin-bottom: .5rem;
    font-size: .8rem;
}

li.sub-menu:hover {
 color: #982e53;
}

.subMenu {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18vw, 1fr));
}

.hide {
    display: none;
}

.hideDisable {
    display: grid;
}

div.hideMenu {
    position: absolute;
    top: -50vh;
    z-index: 5;
    transition: all ease-in 0.5s;
}

div.showMenu {
    position: fixed;
    top: 8vh;
    z-index: 5;
    transition: all ease-in 0.5s;
    background-color: rgba(255,255,255,0.5);
    width: 100vw;
}

.dropdown{
cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 0.9rem;
    font-weight: 400;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
    /* background-color: #ddd; */
    background-color: #982e53;
    color: white;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}


/* Media queries for mobiles */

@media only screen and (max-width: 767px) {
    .navbar {
    height: 12vh;
}

.header-logo {
    width: 35px;
    height: 32px;
    left: 10px;
}

.nav {
    left: 65vw;
}

input.search {
    width: 50vw;
}

form.searchBar {
    left: 4vw;
}

i.uil.uil-search {
    width: 20px;
    font-size: 18px;
}

button.menuBtn {
    left: 5vw;
}

.Sell-btn {
    left: 72vw;
}

.mainCategory {
    width: 30vw;
}
}