.slider-product-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .slider-product-card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  }
  
  .slider-product-card img {
    min-width: 200px;
    max-width: 200px;
    height: 250px;
  }
  
  .product-details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    background-color:rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 0.8);
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .slider-product-card:hover .product-details {
    opacity: 1;
  }
  
  .product-details h3 {
    margin: 0;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  
  .price {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  