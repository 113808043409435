.dashboardSidebar {
    height: 50vh;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

li.sidebarList {
    cursor: pointer;
    padding: 11% 15%;
    display: block;
    font-size: 1.1rem;
    font-weight: 500;
}

li.sidebarList:hover {
    /* background-color: #982E53; */
    /* color: white; */
    /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
}

ul.sidebar-ul {
    padding: 0% 8%;
}

button.lgout-btn {
    padding: 3% 25%;
    font-weight: 600;
    border-radius: 25px;
    border: solid 1px;
    background-color: #982E53;  
    color: white;

}