.signupForm {
	width: 50vh;
	height: max-content;
	display: flex;
	position: absolute;
	left: 107px;
	top: 12vh;
	font-family: Roboto;
	text-align: left;
	font-size: 14px;
	flex-direction: column;
}

.form-input {
	padding: 3px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07000000029802322);
	background-color: rgba(234.29998755455017, 234.29998755455017, 234.29998755455017, 1);
	border-radius: 5px;
	border: none;
}

.image_box {
	box-shadow: 2px 9px 4px rgba(0, 0, 0, 0.10000000149011612);
	width: 62vw;
	height: 100vh;
	position: absolute;
	left: 578px;
	top: 0px;
	background-image: url(../../assets/images/rectangle_55.png);
	background-repeat: no-repeat;
	background-size: cover;
}

.sub_btn {
	background-color:  #982E53;
	color: white;
	width: 18vw;
	border: none;
	border-radius: 5px;
	height: 30px;
	display: block;
	margin: auto;
	margin-top: 0.5vh
}

.sub_btn:hover{
	background-color: transparent;
	border: solid 1px;
	border-color:  #982E53;		
	color:  #982E53
}

.accountSwitch {
    display: block;
    margin: auto;
}

/* Media queries for laptops and desktops */
/* @media screen and (min-width: 1024px) {
    .signupForm {
        width: 50%;
        left: 25%;
    }

    .image_box {
        width: 40%;
        left: 60%;
    }
} */

/* Media queries for tablets */
@media screen and (max-width: 1023px) and (min-width: 768px) {
    .signupForm {
        width: 70%;
        left: 15%;
    }

    .image_box {
        display: none;
    }
}

/* Media queries for mobiles */
@media screen and (max-width: 767px) {
    .signupForm {
        width: 90%;
        left: 5%;
    }

    .image_box {
        display: none;
    }
}