.homepage-slider-banner{
    height : 40vh;
    width:inherit;
}
.homepage-slider-products{
    height : 25vh;
    margin : auto;
}

.carousel .thumbs-wrapper {
   display: none;
}

.homepage-slider-banner .slick-slider{
    width: 90vw;
}