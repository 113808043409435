.addressContainer {
    padding: 1% 2%;
    display: flex;
    justify-content: space-between;
    border: solid 1px;
    border-color: grey;
    border-radius: 10px;

}

div.addAddress {
    padding: 1% 12%;
    border: solid 1px;
    display: flex;
    justify-content: space-between;
    margin-top: 4vh;
    margin-bottom: 3vh;
    border-radius: 10px;
    color: #982E53;
    cursor: pointer;
}

div.addAddress:hover {
    font-weight: 500;
    color: black;
    border: solid 2px
}

.addressRight{
    height: fit-content;
    cursor: pointer;
}


.addressModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 8px #669999;
    z-index: 20;
}

.addressModal form {
    width: 100%;
    padding: 20px;
    background: #fff;
}

.addressFormHeader {
    text-align: center;
    color: #982E53;
}

.item {
    position: relative;
    margin: 10px 0;
}

.item span {
    color: red;
}

.week {
    display: flex;
    justify-content: space-between;
}

.colums {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.colums div {
    width: 48%;
}

.addressFormLabel {
    display: block;
    font-size: 1.1rem;
    font-weight: 500;
}

.addressFormInput {
    width: 90%;
    border-radius: 5px;
    padding: 0.5rem;
}

.addressFormBtnGroup {
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
    gap: 3%;
}

button#closeBtn {
    padding: 2% 6%;
    border-radius: 25px;
    background: grey;
    color: white;
    font-weight: 500;
    border: solid 1px;
}

button#addBtn {
    padding: 2% 10%;
    border-radius: 25px;
    background: #982E53;
    color: white;
    font-weight: 500;
    border: solid 1px;
}

/*  */

.addressRight:hover .options {
    display: flex;
}

.options {
    display: none;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    padding: 8px;
    gap: 8px;
}

.option {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
    color: #333;
    cursor: pointer;
}

.option:hover {
    color: #982E53;
}

.option>i {
    font-size: 1.1rem;
}

.option>span {
    font-weight: 500;
}

.option>i,
.option>span {
    transition: color 0.2s ease-in-out;
}