.cartItems {
    position: relative;
    display: flex;
    gap: 5%;
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-color: lightgrey;
    padding: 15px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
}

div.cartItems img {
    height: 150px;
    width: 130px;
    object-fit: cover;
    border: solid 1px;
}

.itemDetails {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    justify-content: center;
    width: 20%;
}

p#cartProductName {
    max-height: 10vh;
    overflow: hidden;
}

p#ListingPrice {
    margin-left: 1.5%;
    font-weight: bold;
    font-size: 1.2rem;
    display: inline;
}

p#MRP {
    color: grey;
    text-decoration: line-through;
    display: inline;
}

input#Quantity {
    width: 4vw;
    text-align: center;
}

i.add,
i.sub {
    font-size: 1.5rem;
    cursor: pointer;
}

.cart-btn {
    margin-left: 2rem;
    cursor: pointer;
    font-weight: 500;
}

.cart-btn:hover {
    color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1)
}