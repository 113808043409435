.subTotalContainer {
    background: #982E53;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: white;
    /* width: 25%; */
    height: fit-content;
    padding: 20px;
}

.subTotalDetails {
    border-top: solid 1px;
}

.details {
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
}

.totalPrice.details {
    border-top: solid 1px;
    font-size: 1.2rem;
    font-weight: 500;
}

button.buy-btn {
    background: #FFFFFF;
    border-radius: 31.5px;
    padding: 3%;
    color: #982E53;
    font-weight: 600;
    width: 100%;
    border: none;
    margin-top: 4vh;
}

button.buy-btn:hover{
    background: transparent;
    color: white;
    border: solid 1px;
    border-color: white;
}