.footer-container{
        background-color: rgba(152.0000061392784, 46.000001057982445, 83.00000265240669, 1);
        width: 100vw;
        height: 38vh;
        position: relative;
        /* top: 250vh; */
}

.footer-logo{
    width: 259px;
    height: 243px;
    top : 3vh;
    left: 2vw;
    position: absolute;
    background-image: url(../../assets/images/logo.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.footer-list {
    color: white;
    position: absolute;
    top: 14vh;
}

.footerGooglePlayBtn {
    background-image: url(../../assets/images/google_play.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 7vw;
    height: 4vh;
    margin-top: 12px;
}

.followUs {
    position: relative;
    top: 12vh;
    left: 5vw;
    color: white;
}

.footerIcons {
    margin-left: 7px;
}