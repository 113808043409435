p {
  margin: 0;
}

.product-details-container {
  display: flex;
  width: 98%;
  margin: auto;
  position: relative;
  top: 10vh;
  justify-content: space-around;
  min-height: 89vh;
  margin-bottom: 12vh;
}

.thumbnailImageContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1vh;
}

img.thumbnailImage {
  width: 22%;
  height: 11vh;
  object-fit: contain;
}

img.thumbnailImage:hover {
  border: solid 1px #982E53;
}

.leftContainer {
  width: 40%;
  height: inherit;
  background-color: transparent;
}

.product-image {
  background: white;
  padding: 1%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

img.coverImage {
  width: 100%;
  height: 57vh;
  object-fit: contain;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 3vh;
}

.add-to-cart {
  background-color: #982E53;
  color: white;
  padding: 0.5rem 16%;
  height: 6vh;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 1%;
}

.product-info {
  width: 58%;
  padding-left: 1rem;
  line-height: 1.8rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

span#ListingPrice {
  color: black;
  font-size: 1.5rem;
  font-weight: 600;
}

span#productMRP {
  color: #878787;
  text-decoration: line-through;
}

span#discountPercent {
  color: green;
  font-weight: 600;
  font-size: 0.9rem;
}

#Brand {
  color: gray;
  font-size: 1.3rem;
  margin-bottom: 4px;
}

#ProductName {
  color: #982E53;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

p#About {
  margin-top: 5vh;
  font-size: 1.2rem;
  font-weight: 600;
}

p.product-description {
  height: 29vh;
  padding: 0.5rem;
  width: 95%;
  display: block;
  margin: auto;
  overflow-y: auto;
}

.product-price {
  font-size: 1.5rem;
  font-weight: bold;
}

.product-options {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.size-selector,
.quantity-selector {
  width: 49%;
}

.add-to-cart:hover {
  background-color: transparent;
  color: #982E53;
  border-color: #982E53;
  border: solid 1px;
}

.infoSection {
  display: flex;
  gap: 21%;
}

#size {
  font-size: 1.2rem;
  font-weight: 600;
}

#sizeBox {
  border: solid 2px;
  padding: 0.3rem;
  margin-left: 1px;
  background: #982E53;
  border: none;
  color: white;
}

.color-option {
  margin-top: 1rem;
}

div#pageFooter {
  position: relative;
  top: 13vh;
}

.makeOfferModal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
  margin: auto;
  color: white;
}

.offerForm {
  width: 25%;
  height: 30%;
  display: block;
  margin: auto;
  border: solid 2px;
  border-radius: 25px;
  padding: 25px;
  background-color: #ad657e;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.questionAnswerContainer {
  width: 98%;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 2%;
  background-color: white;
}